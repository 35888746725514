<template>
  <ul>
    <li>News</li>
    <li>News</li>
    <li>News</li>
    <li>News</li>
    <li>News</li>
  </ul>
</template>

<script>
export default {
    name: 'News',
}
</script>

<style>

</style>