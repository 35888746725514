<template>
  <div>
    <!-- 我是关于{{$route.query.name}} -->
    我是{{$route.params.name}}, {{$route.params.age}}岁
  </div>
</template>

<script>
export default {
    name: 'About'
}
</script>

<style>

</style>