<template>
  <div>
    <h2>路由测试</h2>
    <hr />
    <div id="nav">
      <ul>
        <li>
          <router-link to="/home">首页</router-link>
        </li>
        <li>
          <!-- <router-link to="/about?name=jack">关于</router-link> -->
          <!-- <router-link to="/about/jack/18">关于</router-link> -->
          <router-link
            :to="{
              name: 'about',
              params: { name: 'jack', age: 18 },
            }"
            >关于</router-link
          >
        </li>
      </ul>
    </div>
    <div id="content">
      <router-view></router-view>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#nav,
#content {
  float: left;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}
#nav {
  width: 200px;
}
#content {
  width: 800px;
}
.clear {
  clear: both;
}
</style>
