<template>
  <ul>
    <li>Message</li>
    <li>Message</li>
    <li>Message</li>
  </ul>
</template>

<script>
export default {
    name: "Message",
}
</script>

<style>

</style>