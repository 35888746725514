import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import HomeNews from '../views/Home/News.vue'
import HomeMessage from '../views/Home/Message.vue'

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/home',
            component: Home,
            children: [
                {
                    path: 'news',
                    component: HomeNews
                },
                {
                    path: 'message',
                    component: HomeMessage
                }
            ]
        },
        {
            name: 'about',
            path: '/about/:name/:age',
            component: About
        }
    ]
})