<template>
  <div>
    我是首页
    <hr />
    <router-link to="/home/news">新闻</router-link>
    <router-link to="/home/message">消息</router-link>
    <br />
    <hr />
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
a {
  float: left;
  margin-left: 5px;
}
</style>